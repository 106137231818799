<template>
  <div>
    <strong>Maintenance Service Record Upload</strong>
    <br>
    <br>
    <div class="form-group">
      <label>Log ID #</label>
      <input class="form-control" type="text" v-model="state.referenceID" />
    </div>
    <div class="form-group">
      <label>Title</label>
      <input type="text" v-model="state.fileName" class="form-control" />
    </div>
  </div>
</template>

<script>
import { fileUploadStore } from "@/store/FileUpload.store";
export default {
  name: "Conforming",
  components: {},
  data() {
    return {
      state: fileUploadStore.state,
    }
  },
  created() {
    fileUploadStore.initialize(this.$cdiVars.FILE_TYPE_MAINTENANCE_SERVICE_RECORD);

  }
}
</script>

<style scoped>
</style>